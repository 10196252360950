import { Injectable } from '@angular/core';
import { AppConstants } from '../../config/constants';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  /**
   * Set session value to be stored
   * @param value String with valid JWT value
   * @param key String with store key name, if empy token is used
   */
  setItem(value: string, key?: string) {
    if (key === undefined) {
      key = AppConstants.STORAGE_NAME;
    }
    localStorage.setItem(key, value);
  }

  /**
   * Get session key value
   * @param key String with store key name, if empy token is used
   */
  getItem(key?: string) {
    if (key === undefined) {
      key = AppConstants.STORAGE_NAME;
    }
    return localStorage.getItem(key);
  }

  /**
   * Remove session key value
   * @param key String with store key name, if empy token is used
   */
  removeItem(key?: string) {
    if (key === undefined) {
      key = AppConstants.STORAGE_NAME;
    }
    localStorage.removeItem(key);
  }

}
