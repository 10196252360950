import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  private baseNumber: number;

  public showIdTinc: boolean = true;

  constructor() {
    this.baseNumber = 10000000;
  }
  assetIdGenerator(id: number) {
    return 'AST' + this.baseNumber + id;
  }
  supplierIdGenerator(id: number) {
    return 'SUP' + this.baseNumber + id;
  }
  requestIdGenerator(id: number) {
    return 'REQ' + this.baseNumber + id;
  }
  serviceIdGenerator(id: number) {
    return 'SERV' + this.baseNumber + id;
  }
  customerIdGenerator(id: number) {
    return 'CTE' + this.baseNumber + id;
  }
  ticketIdGenerator(id: number) {
    return 'TIC' + this.baseNumber + id;
  }


}
